

























































































import { Component, Prop, Vue } from 'vue-property-decorator'

import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import FilesList from '@/components/FilesList.vue'
import Player from '@/components/_uikit/Player.vue'
import QuestionMultipleForm from '@/components/views/exercise/question/QuestionMultipleForm.vue'
import QuestionRatioForm from '@/components/views/exercise/question/QuestionRatioForm.vue'
import QuestionSequenceForm from '@/components/views/exercise/question/QuestionSequenceForm.vue'
import QuestionSingleForm from '@/components/views/exercise/question/QuestionSingleForm.vue'
import QuestionTextForm from '@/components/views/exercise/question/QuestionTextForm.vue'
import RelatedQuestionView from '@/components/views/exercise/RelatedQuestionView.vue'
import {
  EducationLargeTaskDecideQuestionRequest,
  EducationLargeTaskTestQuestionResource,
  NameValueResource,
  TaskQuestionType,
} from '@/store/types'
import { downloadOriginalFile } from '@/utils/services/config'

@Component({
  components: {
    ButtonArrow,
    FilesList,
    Player,
    QuestionMultipleForm,
    QuestionRatioForm,
    QuestionSequenceForm,
    QuestionSingleForm,
    QuestionTextForm,
    RelatedQuestionView,
  },
})
export default class TestQuestionPassingView extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskTestQuestionResource

  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private formQuestion!: EducationLargeTaskDecideQuestionRequest

  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: null })
  private relatedQuestion!: EducationLargeTaskTestQuestionResource | null

  private showRelatedQuestion: boolean | number | undefined = false

  private get imageFiles() {
    return this.question.media.filter(media => media.type === 'image')
  }

  private get audioFiles() {
    return this.question.media.filter(media => media.type === 'audio')
  }

  private get mediaCount() {
    return this.imageFiles.length
  }

  private get mediaFiles() {
    return this.subject.value !== 14 ? this.question.media.filter(media => media.type === 'file') : this.question.media.filter(media => media.type === 'file').map(media => ({
      ...media,
      url: `${downloadOriginalFile}${media.id}`,
    }))
  }

  // Нечетное ли кол-во медиа
  private get isEvenMediaCount() {
    return Boolean(this.mediaCount % 2)
  }

  private get isQuestionMultiple () {
    return this.question.type.value === TaskQuestionType.MULTIPLE
  }

  private get isQuestionRatio () {
    return this.question.type.value === TaskQuestionType.RATIO
  }

  private get isQuestionSequence () {
    return this.question.type.value === TaskQuestionType.SEQUENCE
  }

  private get isQuestionSingle () {
    return this.question.type.value === TaskQuestionType.SINGLE
  }

  private get isQuestionText () {
    return this.question.type.value === TaskQuestionType.TEXT
  }
}
